/**
** JAVASCRIPT
** Name: Contact
********************************************************************************/

import $ from 'jquery';

(function() {
  'use strict';

  if($('.js-contact-form').length > 0) {


    // Variables

    var $form      = $('.js-contact-form'),
        $object    = $form.find('.c-form__field[data-id="object"]'),
        $objectSelect   = $object.find('select'),
        $business  = $form.find('.c-form__field[data-id="business_sector"]'),
        $businessSelect = $business.find('select'),
        $reference = $form.find('.c-form__field[data-id="reference_file"]'),
        $referenceField = $reference.find('input'),
        $repairCenters = $form.find('.c-form__field[data-id="repair_center"]'),
        $repairCentersSelect = $repairCenters.find('select');


    // Functions

    function setContactFields(object) {
      if(object == "business") {
        $object.after($business);
        $business.show();
        $repairCenters.hide();
        $repairCenters.detach();
        $reference.hide();
        if($referenceField.val() == '') {
          $referenceField.val(' ');
        }
      }
      else if(object == "information_sav") {
        $business.hide();
        $business.detach();
        $object.after($repairCenters);
        $repairCenters.show();
        $reference.show();
        if($referenceField.val() == ' ') {
          $referenceField.val('');
        }
      }
      else {
        $business.hide();
        $business.detach();
        $repairCenters.hide();
        $repairCenters.detach();
        $reference.hide();
        if($referenceField.val() == '') {
          $referenceField.val(' ');
        }
      }
    }


    // Document ready

    $(function() {
      setContactFields($objectSelect.val());
    });


    // On change

    $objectSelect.on('change', function() {
      setContactFields($objectSelect.val());
    });

  }

})();
